<template>
    <el-dialog width="600" :title="title" :visible="dialogVisible" @close="dialogDataOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small">
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                    <el-form-item prop="serviceId" v-bind:label="$t('Operator')"  v-if="serviceList.length>0&&!this.isModify">
                        <el-select id="serviceId" v-model="updateQuery.serviceId" :disabled="this.isModify" size="small" class="filter-item hundred-percent" >
                            <el-option
                                v-for="item in this.serviceList"
                                :label="item.name"
                                :key="item.id"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                    <el-form-item prop="note" :label="$t('Description')" >
                        <el-input id="note" size="small" v-model="updateQuery.note" :placeholder="$t('EnterApiKeyDescription')" class="filter-item hundred-percent" clearable style="text-align: left; margin-left: 0px;" />
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22" style="text-align: left; ">
                    <el-form-item prop="services" :label="$t('ApiService')" >
                        <el-table ref="serviceTable" v-loading="listLoading" :data="list" align="center"  @selection-change="handleSelectionChange" >
                            <el-table-column type="selection" width="55" ></el-table-column>
                            <el-table-column align="left" prop="paths" :label="$t('ApiServiceEndpoint')" />
                            <el-table-column align="left" prop="name" :label="$t('ApiServiceName')" />
                            <el-table-column align="left" prop="description" :label="$t('ApiServiceDescription')" />
                        </el-table>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary"
            @click="updateData()">{{$t('Save')}}</el-button>

        <br/><br/>
    </el-dialog>
</template>
<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";
export default {
    name: 'AppKeySetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        appKeyData: {
            require: false,
            default: undefined,
            type: Object
        },
        serviceList: {
            require: false,
            default: undefined,
            type: Array
        },
        dialogVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            dialogWidth: 0,
            total: 0,
            list: [],
            listLoading: false,
            isModify: false,
            updateQuery: {
                id: undefined,
                note: undefined,
                apiKey: undefined,
                clientApp: undefined,
                serviceId: undefined,
                services: [],
                enabled: true
            },
            title: this.$t('CreateApiKey'),
            checkAll: false,
            isCheck: false,
            isIndeterminate: true,
            checkedCities: ['Shanghai', 'Beijing'],
            cities:  ['Shanghai', 'Beijing', 'Guangzhou', 'Shenzhen'],
            rules: {
                note: [{ required: true, message: this.$t('Required'), trigger: 'blur' }]
            },
            selServiceList: [],
            kongId: undefined
        };
    },
    watch: {
        appKeyData(val) {
            if (val) {
                this.title = this.$t('EditApiKey')
                this.isModify = true
                console.log("isModify:" + this.isModify);
                this.updateQuery.id = val.id
                this.updateQuery.note = val.note
                this.updateQuery.apiKey = val.apiKey
                this.updateQuery.clientApp = val.clientApp
                this.updateQuery.enabled = val.enabled
                this.loadBindService()
            }
        }

    },
    async created() {
        if(this.$route.query.kong != undefined) {
            this.kongId = this.$route.query.kong
        }
        this.getKongServiceList()
    },
    methods: {
        getKongServiceList() {
            this.listLoading = true
            let serviceUrl = '/api/v1/kong/manage/services'
            if(this.kongId != undefined) serviceUrl = serviceUrl + '?kong=' + this.kongId
            this.axios.get(serviceUrl).then(res => {
                console.log(res)
                this.list = res.data
                this.listLoading = false
            })
        },
        loadBindService() {
            this.axios.get('/api/v1/kong/consumers/'+this.updateQuery.clientApp+'/acls').then(res => {
                console.log("acls" + res)
                this.list.forEach(serve => {
                    if(res.data.includes(serve.name)) {
                        this.$refs.serviceTable.toggleRowSelection(serve);
                    }
                });
            })
        },
        handleSelectionChange(selection) {
            this.selServiceList = []
            selection.forEach(s => {
                this.selServiceList.push(s.name)
            });
            // console.log('delWaterNumList', this.delWaterNumList)
        },
        dialogDataOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        // 一般篩選條件上傳
        updateData() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    // update
                    const requestData = {
                        "clientApp": this.updateQuery.clientApp,
                        "note": this.updateQuery.note,
                        "groups": this.selServiceList,
                        "serviceId": this.updateQuery.serviceId
                    }
                    this.axios.put('/api/v1/appkey', requestData).then(() => {
                        this.$message({
                            showClose: true,
                            message: this.$t('SuccessEdited'),
                            type: 'success'
                        })
                        this.dialogDataOnClose()
                    }).catch(err => {
                        console.log('err:' + err)
                        this.$message({
                            message: this.$t('FailEdited'),
                            type: 'error'
                        })
                    })

                } else {
                    this.$message({
                        message: this.$t('IncorrectFormat'),
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.isModify = false
            this.updateQuery = {
                id: undefined,
                note: undefined,
                apiKey: undefined,
                clientApp: undefined,
                enabled: true
            },
            this.selServiceList = [];
            this.title = this.$t('CreateApiKey');
            // 清空選取的項目
            this.$refs.serviceTable.clearSelection();
        },
        handleCheckAllChange(val) {
            this.checkedCities.value = val ? this.cities : []
            this.isIndeterminate.value = false
        },
        handleCheckedCitiesChange(value) {
            const checkedCount = value.length
            this.checkAll.value = checkedCount === this.cities.length
            this.isIndeterminate.value = checkedCount > 0 && checkedCount < this.cities.length
        },
        selectBox() {
            this.isCheck = !this.isCheck
            console.log(this.isCheck)
            let list = [...this.list]
            for(let val of list) {
                val.itemCheck = this.isCheck;
            }
            this.list = list
        },
        renderHeader(h, data) {
            return h("span", [
                h("el-checkbox"), {
                    on: {
                        change: this.selectBox
                    },
                    props: {
                        value: this.isCheck,
                        indeterminate: this.isIndeterminate
                    }
                }
            ]);
        }
    }

}
</script>

<style>
.el-form-item__error {
    top: 80%
}
label {
    font-weight: bold
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) { 
    .el-form-item__content {
        text-align: left;
    }
    
    
}

@media (min-width: 768px) { 
    
    .hundred-percent {
        width: 100%;
    }
    
    .el-form-item__content {
        text-align: left;
        margin-left: 120px;
    }

    .el-form-item__label {
        width: 120px;
    }
 }
</style>
