<template>
    <div>
        <el-row>
            <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handleAdd">
                {{$t('CreateApiKey')}}
            </el-button>
            <el-button style="float: left" size="mini" type="success" icon="el-icon-plus" @click="handleAddService" v-if="isSysAdmin">
                {{$t('RegisterApiService')}}
            </el-button>
            <div style="float: right;">
                <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
                        <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
                </el-select>
            </div>
        </el-row>
        <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
            <span >產製時間: {{ reportDate }} </span>
        </el-row>
        <el-table v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{ padding: '0', height: '40px' }" @sort-change="tableSortChange">
            <el-table-column align="left" prop="apiKey" :label="$t('ApiKeyHeader')" width="320px"/>
            <el-table-column align="left" prop="note" :label="$t('Description')"  />
            <!-- <el-table-column align="left" prop="clientApp" label="clientApp" width="280px"/> -->
            <el-table-column align="left" prop="serviceInfo.name" :label="$t('Operator')" v-if="serviceList.length>0" />
            <el-table-column align="left" prop="updateTime" :label="$t('Update')+$t('Date')" width="160px"/>
            <el-table-column align="left" prop="createTime" :label="$t('Create')+$t('Date')"  width="160px"/>
            <el-table-column align="center" :label="$t('Operate')" width="130px" >
                <template slot-scope="scope">
                    <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showSetting(scope.row)" />
                    <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" @click="handleDelete(scope.$index, scope.row)" />
                </template>
            </el-table-column>
        </el-table>
        <!--分頁組件-->
        <pagination v-show="total > 0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
        <AppKeySetting :dialogVisible="dialogSettingVisible" :appKeyData="appKeyData" :serviceList="serviceList" @close="dialogSettingOnClose()" />
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import AppKeySetting from './AppKeySetting.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import {apiUrl} from "@/utils/main";
import api from "@/utils/api";
export default {
    name: 'apikey',
    components: {
        Pagination,
        AppKeySetting
    },
    mixins: [mixins],
    data() {
        return {
            dialogWidth: 0,
            total: 0,
            list: [],
            listLoading: false,
            listQuery: {
                type: 'general',
                enabled: true,
                name: undefined,
                serviceId: undefined,
                page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                size: 15
            },
            paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
            dialogSettingVisible: false,
            appKeyData: undefined,
            serviceList: [],
            isSysAdmin: false,
            reportDate: this.formatCurrentDateTime()
        }
    },
    watch: {
        paginationSelectedPage: {
            handler: function (newValue) {
                this.listQuery.page = newValue - 1
            }
        },
        'listQuery.serviceId': function () {
            this.listQuery.page = 0
            this.resetPage(this.getList)
        }
    },
    created() {
        this.dialogWidth = this.setDialogWidth();
        this.getServiceOptions();
        this.getCurrentAccountPermission();
        this.resetPage(this.getList);
        //refreshToken();
    },
    beforeDestroy(){
      console.log("call beforeDestroy");
      //stopRefreshToken();
    },
    methods: {
        getList() {
            this.listLoading = true
            this.axios.get('/api/v1/appkey', { params: this.listQuery }).then(res => {
                this.list = res.data.content
                this.total = res.data.totalElements
                this.listLoading = false

                this.reportDate = this.formatCurrentDateTime()
            })
        },
        getServiceOptions() {
            this.listLoading = true
            this.axios.get('/api/v1/common/service/enabled').then(res => {
                this.serviceList = res.data
            })
        },
        getCurrentAccountPermission() {
            this.axios.get('/api/v1/common/currentAccount/permission').then(res => {
                if(res.data == "ADMIN") this.isSysAdmin = true
            })
        },
        handleAdd() {
            this.dialogSettingVisible = true
        },
        handleAddService() {
            const kongPage = window.open('','_blank');
            this.axios.get('/api/v1/kong/url').then(res => {
                kongPage.location = res.data;
            })
        },
        handleDelete(index, row) {
            this.$confirm(this.$t('Hint'), {
                message: this.$t('DeleteApiKey')+'-' + row.apiKey +'?',
                confirmButtonText: this.$t('Confirm'),
                cancelButtonText:  this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                this.delApiKey(index, row.apiKey)
            }).catch(() => {
                console.log('cancel delete account')
            })
        },
        async delApiKey(index, apiKey) {
            this.axios.delete('/api/v1/appkey/' + apiKey).then(() => {
                this.$delete(this.list, index);
                this.$message({
                    showClose: true,
                    message: this.$t('Delete') + this.$t('Success'),
                    type: 'success'
                })
            }).catch(err => {
                console.log('err:' + err)
                this.$message({
                    message: this.$t('Delete') + this.$t('Fail'),
                    type: 'error'
                })
            })
        },
        showSetting(row) {
            this.dialogSettingVisible = true
            this.appKeyData = row
        },
        dialogSettingOnClose() {
            this.dialogSettingVisible = false
            this.appKeyData = undefined
            this.resetPage(this.getList)
        },
    }
}
</script>
